var pomlo_app = {};

// Define constants
pomlo_app.BREAKPOINTS = {
    SM: 576,
    MD: 768,
    LG: 992,
    XL: 1140
};
pomlo_app.DEFAULT_DURATION = 300;
pomlo_app.XSMALL_HEADER_HEIGHT = 100;
pomlo_app.HEADER_HEIGHT = 126;
pomlo_app.PREHEADER_HEIGHT = 40;
pomlo_app.map = null;

// Function to define header style (default/small)
pomlo_app.toggleHeaderStyle = function() {

    function setSmallHeader() {
        jQuery('body').addClass('header--small');
        jQuery('#navbar-container').addClass('navbar--small');
        jQuery('#mobile-menu').addClass('mobile-menu--small');
    }

    function unsetSmallHeader() {
        jQuery('body').removeClass('header--small');
        jQuery('#navbar-container').removeClass('navbar--small');
        jQuery('#mobile-menu').removeClass('mobile-menu--small');
    }

    if ((jQuery(window).width() < pomlo_app.BREAKPOINTS.LG) || (jQuery(window).scrollTop() > pomlo_app.HEADER_HEIGHT)) {
        setSmallHeader();
    } else {
        unsetSmallHeader();
    }

    if (jQuery(window).scrollTop() > pomlo_app.HEADER_HEIGHT ) {
        jQuery('#top-header').slideUp(300);
        jQuery('#mobile-menu').removeClass('mobile-menu--top-header');
    } else {
        jQuery('#top-header').show();
        jQuery('#mobile-menu').addClass('mobile-menu--top-header');
    }
};
//Debounced versions of toggleHeaderStyle
pomlo_app.debouncedToggleHeaderStyle = debounce(pomlo_app.toggleHeaderStyle, 100);


pomlo_app.scrollTo = function(selector) {
    jQuery(selector).animatescroll( {
        padding: (jQuery(window).width() > pomlo_app.BREAKPOINTS.SM) ? pomlo_app.HEADER_HEIGHT : pomlo_app.XSMALL_HEADER_HEIGHT,
        scrollSpeed: 600,
        easing: 'easeInOutQuad'
    });
};

// Observer and animation functions
pomlo_app.observeSection = function(selector, func, options) {
    var section = document.querySelector(selector);

    var sectionObserver = new IntersectionObserver(function(entries, observer) {
        entries.forEach(function(entry) {
            if (entry.intersectionRatio > 0) {
                func(options);
                observer.unobserve(entry.target);
            }
        });
    });

    if (section) {
        sectionObserver.observe(section);
    }
};

pomlo_app.animateRoomSummary = function() {
    jQuery('.room-summary').each(function() {
        jQuery(this).addClass('animate__animated animate__fadeIn animate__slow');
    });
};

pomlo_app.animateHomeNewsItem = function() {
    jQuery('.home-news__item').each(function() {
        jQuery(this).addClass('animate__animated animate__fadeInRight animate__slow');
    });
};
pomlo_app.animateNewsSummary = function(selector) {
    jQuery(selector).addClass('animate__animated animate__fadeIn animate__slow');
};
// /Observer and animation functions

pomlo_app.initContactSection = function() {
    // FIXME : pomlo_app.BREAKPOINTS.LG not working
    if(jQuery(window).width() < 974) {
        if(jQuery('#contact__map').css("height") != 500){
            jQuery('#contact__map').css("height", 500);
        }
    } else {
        jQuery('#contact__map').css("height", jQuery('#contact__panel').outerHeight() + 90);
    }
};

pomlo_app.initContactMapCenter = function() {
    // FIXME : pomlo_app.BREAKPOINTS.LG not working
    if(jQuery(window).width() < 974) {
        pomlo_app.map.setCenter([0.5426556, 48.5202054]);
    } else {
        pomlo_app.map.setCenter([0.5364723, 48.5202054]);
    }
};

pomlo_app.initContactMap = function() {
    mapboxgl.accessToken = mapBoxToken;
    pomlo_app.map = new mapboxgl.Map({
        container: 'contact-map',
        style: 'mapbox://styles/mapbox/dark-v10',
        center: [0.5426556, 48.5202054],
        zoom: 13
    });

    var popup = new mapboxgl.Popup({className: 'popup'})
      .setLngLat([0.5426556, 48.5202054])
      .setHTML(mapBoxAddress ? mapBoxAddress : "<strong>La Demeure des Anges</strong></br>9 rue du Faubourg Saint Langis - 61400 MORTAGNE AU PERCHE - France")
      .setMaxWidth("320px")
      .addTo(pomlo_app.map);

    var marker = new mapboxgl.Marker({color: '#BE9A45'})
        .setLngLat([0.5426556, 48.5202054])
        .setPopup(popup)
        .addTo(pomlo_app.map);

     var nav = new mapboxgl.NavigationControl();
     pomlo_app.map.addControl(nav, 'bottom-right');
     pomlo_app.map.scrollZoom.disable();
};

pomlo_app.displayFixedBookingButton = function() {
    if(jQuery(window).scrollTop() > pomlo_app.HEADER_HEIGHT) {
        jQuery("#booking__link").addClass("booking__link--show");
    } else {
        jQuery("#booking__link").removeClass("booking__link--show");
    }
};


// Taking care of search form logic on Rooms page
pomlo_app.searchForm = function() {
    jQuery("#search-form__submit").click(function() {
        jQuery("#search-form__error").empty();

        if(!jQuery("#adults").val() || jQuery("#adults").val() < 1 || jQuery("#adults").val() > 2) {
            jQuery("#search-form__error").append(searchFormAdultsError);
        } else if(jQuery("#children").val() > 0) {
            hideAll(searchFormChildrenError);
        } else {
            var results = [];
            // Fake click on booking button
            jQuery(".wpbs_s-search-widget-datepicker-submit").click();
            // Showing loader
            jQuery("#search-form__loader").removeClass("d-none");

            // Getting start and end date
            var startDate = jQuery(".wpbs_s-search-widget-datepicker-standard-format-start-date").val();
            var endDate = jQuery(".wpbs_s-search-widget-datepicker-standard-format-end-date").val();
            var adults = jQuery("#adults").val();
            var startDateSplit = startDate.split("-");
            var month = startDateSplit[1];
            var year = startDateSplit[0];


            // Select the parent node where you expect the changes to occur
            const parentNode = document.querySelector('.wpbs_s-search-widget');

            // Configuration options for observing : Look for added/removed child nodes and for all descendants
            const config = {
                childList: true,
                subtree: true
            };

            // Callback function executed when mutations are observed
            const callback = function (mutationsList, observer) {
                for (const mutation of mutationsList) {
                    if (mutation.type === 'childList') {
                        mutation.addedNodes.forEach(node => {
                            // Check if the added node has the desired class
                            if (node.nodeType === 1 && jQuery(node).hasClass("wpbs_s-search-widget-results-wrap")) {
                                // Hiding loader
                                jQuery("#search-form__loader").addClass("d-none");

                                if (jQuery(".wpbs_s-search-widget-result-title").length) {
                                    // Adding each result on array
                                    jQuery(".wpbs_s-search-widget-result-title > h3").each(function () {
                                        results.push(jQuery(this).text());
                                    });

                                    jQuery(".rooms__item").each(function () {
                                        // Populating room links with start and end dates
                                        var roomLink = jQuery(this).find("#room-summary-link");
                                        roomLink.attr("href", roomLink.attr("href").split('?')[0] + '?wpbs-start-month=' + month + '&wpbs-start-year=' + year + '&wpbs-selection-start=' + startDate + '&wpbs-selection-end=' + endDate + '&wpbs-field-7=' + adults);
                                        // Checking if block has to be hidden and if its visible now (jQuery.inArray return -1 if false)
                                        (jQuery.inArray(jQuery(this).find("h3").text(), results) == -1 && !jQuery(this).hasClass("d-none")) ? jQuery(this).addClass("d-none") : jQuery(this).removeClass("d-none");
                                    });

                                    // Showing reset button and scrolling to rooms
                                    jQuery("#search-form__reset").removeClass("d-none");
                                    pomlo_app.scrollTo("#rooms");
                                } else {
                                    hideAll(searchFormNoResults);
                                }
                            }
                        });
                    }
                }
            };

            // Create a new MutationObserver instance and start observing the parent node
            const observer = new MutationObserver(callback);
            observer.observe(parentNode, config);
        }
    });

    jQuery("#search-form__reset").click(function() {
        // Resetting elements : url parameters, form fields and hidding any error
        window.history.replaceState(null, null, window.location.pathname);
        jQuery("#children, #adults, .wpbs_s-search-widget-datepicker, .wpbs_s-search-widget-datepicker-standard-format-start-date, .wpbs_s-search-widget-datepicker-standard-format-end-date").val('');
        jQuery("#search-form__error").empty();

        // Hidding reset button, showing rooms and scrolling to top of form
        jQuery('#search-form__reset').addClass("d-none");
        jQuery(".rooms__item").removeClass("d-none");

        pomlo_app.scrollTo("#page-top");
    });

    var hideAll = function(error){
        jQuery("#search-form__error").empty();
        jQuery("#search-form__error").append(error);
        // Showing reset button, hidding rooms
        jQuery("#search-form__reset").removeClass("d-none");
        jQuery(".rooms__item").addClass("d-none");
    };
};

// Taking care of search form logic on Rooms page
pomlo_app.bookingForm = function() {
    jQuery('#booking__submit').click(function() {
        jQuery("#booking__error").empty();
        var children = jQuery('.children').find("input").val();
        var adults = jQuery('.adults').find("input").val();

        jQuery(document).on('DOMNodeInserted', function(e) {
            jQuery('.children, .adults').find('input').prop("type", "number");
            jQuery('.children, .adults').find('input').attr("min", 0).attr("max", 2);
        });

        if(children > 0) {
            jQuery("#booking__error").append(formChildrenError);
        } else if (adults <= 0 || adults > 2){
            jQuery("#booking__error").append(formAdultsError);
        } else {
            jQuery('#wpbs-form-1').submit();
        }

    });

    jQuery(document).on('DOMNodeInserted', function(e) {
        if(jQuery(e.target).hasClass("wpbs-form-confirmation-message")) {
            jQuery('#booking__submit').css("display", "none");
        }
    });
};

jQuery(document).ready(function(jQuery) {

    // Init interface
    pomlo_app.toggleHeaderStyle();

    jQuery(".owl-carousel").each(function(){
        jQuery(this).owlCarousel({
            items: 1,
            loop: true,
            autoplay: true,
            dots: true,
            animateOut: 'fadeOut'
      });
    });

    if (jQuery(".news-index").length) {
        jQuery(".news-index").imagesLoaded( function() {
            jQuery('.grid').masonry({
                itemSelector: '.grid-item',
                columnWidth: '.grid-sizer',
                percentPosition: true,
                horizontalOrder: true
            });
        });
    }

    if (jQuery(".gallery").length) {
        jQuery(".gallery").imagesLoaded( function() {
            jQuery('.gallery').masonry({
                itemSelector: '.gallery__item',
                columnWidth: '.gallery__sizer',
                percentPosition: true,
                horizontalOrder: false
            });
        });
    }

    if(jQuery("#booking__link").length) {
        pomlo_app.displayFixedBookingButton();
    }

    // Setup intersection observers to trigger actions
    pomlo_app.observeSection('#rooms', pomlo_app.animateRoomSummary);

    pomlo_app.observeSection('#home-news', pomlo_app.animateHomeNewsItem);

    jQuery('.news-summary').each(function() {
        var selector = '#' + jQuery(this).attr('id');
        pomlo_app.observeSection(selector, pomlo_app.animateNewsSummary, selector);
    });

    // Loader
    jQuery('.img-loader-wait').imagesLoaded(function() {
        console.log('Images loaded');
        jQuery('.img-bg-loader-wait').imagesLoaded( { background: true }, function() {
            console.log('Background image loaded');

            var delay = document.location.host ? 0 : 500;
            setTimeout(function(){
                jQuery("#loader").fadeOut();
            }, delay);
        });
    });


    // Menu management
    jQuery("#toggler--open").click(function() {
        jQuery("#mobile-menu").addClass("mobile-menu--active");

        jQuery('body').css({
            'overflow': 'hidden'
        });
    });

    jQuery("#toggler--close").click(function() {
        jQuery("#mobile-menu").removeClass("mobile-menu--active");

        jQuery('body').css({
            'overflow': ''
        });
    });


    // Booking section scroll
    jQuery("#booking__arrow").click(function() {
        jQuery(this).children(".icon").toggleClass("icon--rotate");
    });

    jQuery("#booking__link").click(function() {
        if(!jQuery("#booking__content").hasClass("show")) {
            jQuery("#booking__arrow").click();
        }
    });

    // Room summary opacity filters
    jQuery(".room-summary").hover(function() {
        jQuery(this).children(".opacity-filter").addClass("opacity-filter--black-60");
    }, function() {
        jQuery(this).children(".opacity-filter").removeClass("opacity-filter--black-60");
    });

    // Service detail toggle
    jQuery(".service__toggler").click(function() {
        jQuery("#service-detail").addClass("service-detail--show");

        var parent = jQuery(this).parent();
        var serviceIconSrc = jQuery(parent).children("#service__icon").attr('src');
        jQuery("#service-detail").children("#service-detail__icon").attr('src', serviceIconSrc);

        var serviceTitle = jQuery(parent).children(".service__title").text();
        jQuery("#service-detail").find("#service-detail__content__title").text(serviceTitle);

        var serviceBody = jQuery(parent).children("#service__body").text();
        jQuery("#service-detail").find("#service-detail__content__body").text(serviceBody);

        if(jQuery(window).width() < pomlo_app.BREAKPOINTS.MD) {
            jQuery('body').css({
                'overflow': 'hidden'
            });
        }
    });

    jQuery("#service-detail--close").click(function() {
        jQuery("#service-detail").removeClass("service-detail--show");

        if(jQuery(window).width() < pomlo_app.BREAKPOINTS.MD) {
            jQuery('body').css({
                'overflow': ''
            });
        }
    });

    // Taking care of search form and room-summary blocks on rooms page
    if(jQuery(".wpbs_s-search-widget").length) {
        pomlo_app.searchForm();
        jQuery('.wpbs_s-search-widget-datepicker').attr('readonly', true);
    }

    // Taking care of booking form on room page
    if(jQuery("#booking").length) {
        jQuery('#wpbs-form-submit-1').css("display", "none");
        jQuery('.children, .adults').find('input').prop("type", "number");
        jQuery('.children, .adults').find('input').attr("min", 0).attr("max", 2);

        if(window.location.search.split('?').length >= 2) {
            pomlo_app.scrollTo("#booking");
        }

        pomlo_app.bookingForm();
    }

    // Manage Animatescroll links
    jQuery('.scroll-to').removeAttr('href');

    jQuery('.scroll-to').click(function() {
        var scrollLink = jQuery(this).attr('data-link');
        pomlo_app.scrollTo(scrollLink);
    });

    if(jQuery('#contact-map').length) {
        pomlo_app.initContactSection();
        pomlo_app.initContactMap();
        pomlo_app.initContactMapCenter();
    }

});

jQuery(window).scroll(function() {
    pomlo_app.debouncedToggleHeaderStyle();

    if(jQuery("#booking__link").length) {
        pomlo_app.displayFixedBookingButton();
    }
});

jQuery(window).resize(function() {
    pomlo_app.debouncedToggleHeaderStyle();

    if(jQuery('#contact-map').length) {
        console.log(jQuery('#contact-map'));
        pomlo_app.initContactSection();
        pomlo_app.initContactMapCenter();
    }
});
